export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  dates: string[];
  solarChargeCurrentGraph: (number | null)[];
  batteryCurrentGraph: (number | null)[];
  systemState: number;
  solarEnergyGraph: (number | null)[];
  totalSolarEnergy: number;
  totalSolarPower: number;
  totalHours: number;
  loadGraph: (number | null)[];
  totalLoad: number;
  maxSolarPower: number;
  maxPowerTimestamp: string;
  stateOfCharge: number;
  voltageGraph: (number | null)[];
  lastDate: string;
  temperatureGraph: (number | null)[];
  dailyLoadArr: {
    date: string;
    lastNumber: number;
  }[];
  dailySolarGenArr: {
    date: string;
    lastNumber: number;
  }[];
  SOCGraph: (number | null)[];
  alertsMap: any[];
  lastGenerator: number;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
