import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";
import popupReducer from "./popupReducer";
import vehicleSelectionReducer from "./vehicleSelectionReducer";
import settingsReducer from "./settingsReducer";
import timestampWeatherReducer from "./timestampWeatherReducer";
import fleetReducer from "./fleetReducer";
import DateSelectionReducer from "./dateSelectionReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  popupView: popupReducer,
  vehicleSelection: vehicleSelectionReducer,
  settings: settingsReducer,
  timestampWeather: timestampWeatherReducer,
  fleet: fleetReducer,
  dateSelections: DateSelectionReducer,
});

export default rootReducer;
